.c-switch-bar[data-v-3df3dca2] {
  position: fixed;
  border-color: #dedede;
  z-index: 10;
}
.c-switch-bar .search-btn[data-v-3df3dca2] {
  color: #333;
}
.c-switch-bar .tab[data-v-3df3dca2] {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.c-switch-bar .tab .item[data-v-3df3dca2] {
  width: 1.25rem;
  height: 0.6rem;
  line-height: 0.6rem;
  text-align: center;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  border: solid 1px #59b6d7;
  color: #59b6d7;
  font-size: 0.32rem;
}
.c-switch-bar .tab .item.active[data-v-3df3dca2] {
  background: #59b6d7;
  color: #fff;
}
.c-switch-bar .tab .item[data-v-3df3dca2]:last-child {
  border-top-right-radius: 0.06rem;
  border-bottom-right-radius: 0.06rem;
}
.c-switch-bar .tab .item[data-v-3df3dca2]:first-child {
  border-top-left-radius: 0.06rem;
  border-bottom-left-radius: 0.06rem;
}
.page-question[data-v-04ed512c] {
  padding-bottom: 0 !important;
}
.p-post-question-main > div[data-v-2e354293] {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
}
.p-post-question-main .m-pos-f[data-v-2e354293] {
  top: 0.9rem;
}
.p-post-question-main .question-title[data-v-2e354293] {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  padding: 0.3rem;
  border-bottom: 1px solid #ededed;
}
.p-post-question-main .question-title.step3[data-v-2e354293] {
  padding: 0.4rem;
}
.p-post-question-main .question-title input[data-v-2e354293] {
  font-size: 0.3rem;
  line-height: 1.5;
  width: 100%;
}
.p-post-question-main .question-list[data-v-2e354293] {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  overflow: auto;
}
.p-post-question-main .question-list li[data-v-2e354293] {
  border-bottom: 1px solid #ededed;
  color: #666;
  font-size: 0.3rem;
  padding: 0.4rem;
}
.p-post-question-main .m-reles-body[data-v-2e354293] {
  height: auto;
  margin-bottom: 0;
}
.p-post-question-main .m-reles-body textarea[data-v-2e354293] {
  font-size: 0.32rem;
  line-height: 1.5;
  overflow: auto;
  margin-top: 0.35rem;
  padding: 0 0.3rem;
  background-color: transparent;
  outline: 0;
  border: 0;
  resize: none;
  width: 100%;
  max-height: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
}
.p-post-question-main .anonymity-switch[data-v-2e354293] {
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #ededed;
}
.m-rich-box[data-v-2e354293] {
  font-size: 0.3rem;
  width: 100%;
  padding: 0.4rem;
  position: relative;
  overflow: auto;
}
.m-rich-box .placeholder[data-v-2e354293] {
  position: absolute;
  color: #ccc;
}
.m-editor[data-v-2e354293] {
  border: 0;
  outline: 0;
  line-height: 1.5;
  word-wrap: break-word;
  overflow-x: hidden;
  overflow-y: auto;
  _overflow-y: visible;
  -webkit-user-modify: read-write-plaintext-only;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  -webkit-user-select: text;
}
.m-topics[data-v-2e354293] {
  overflow-y: auto;
}
.m-topics.ml[data-v-2e354293] {
  margin-left: 0.15rem;
}
.m-topics li.m-topic[data-v-2e354293] {
  float: left;
  margin-left: 0.15rem;
  margin-top: 0.2rem;
  padding: 0.1rem 0.2rem;
  min-height: 0.5rem;
  line-height: 0.32rem;
  border-radius: 0.25rem;
  background-color: rgba(89, 182, 215, 0.2);
  color: #666;
  font-size: 0.26rem;
}
.m-topics li.m-topic > .m-svg-def[data-v-2e354293] {
  margin-left: 0.2rem;
  width: 0.32rem;
  height: 0.32rem;
  fill: #b2b2b2;
}
.m-topics div.m-topic[data-v-2e354293] {
  padding: 0.3rem;
}
.m-topics div.m-topic .m-topic-avatar[data-v-2e354293] {
  width: 0.8rem;
  height: 0.8rem;
  background-color: #ccc;
  margin-right: 0.3rem;
}
.m-topics div.m-topic h3[data-v-2e354293] {
  font-size: 0.3rem;
  line-height: 1.5;
}
.m-topics div.m-topic p[data-v-2e354293] {
  width: 100%;
  margin-top: 0.09rem;
  overflow: hidden;
  font-size: 0.24rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #999;
}
.p-question-list[data-v-3dc8e15f] {
  padding-top: 1.8rem;
  padding-bottom: 0.01rem;
  min-height: 100vh;
}
.p-question-list .nav[data-v-3dc8e15f] {
  position: fixed;
  top: 0.9rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  max-width: 7.68rem;
  height: 0.9rem;
  background: #fff;
  color: #999;
  font-size: 0.3rem;
  border-bottom: solid 1px #dedede;
  z-index: 10;
}
.p-question-list .nav > a[data-v-3dc8e15f] {
  color: #999;
}
.p-question-list .nav .active[data-v-3dc8e15f] {
  color: #333;
}
.p-question-list .main .main-loading[data-v-3dc8e15f] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.24rem;
}
.p-question-list .main .main-loading_icon[data-v-3dc8e15f] {
  width: 1.2rem;
  height: 0.3rem;
  fill: #58b6d7;
}
.p-question-list .main .main-loadmore[data-v-3dc8e15f] {
  margin: 0.24rem auto;
  text-align: center;
}
.p-question-list .main .main-loadmore_button[data-v-3dc8e15f] {
  padding: 0.12rem 0.24rem;
  color: #58b6d7;
  background-color: transparent;
  border: solid 1px #58b6d7;
  border-radius: 0.06rem;
  outline: none;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.p-question-list .main .main-loadmore_button.active[data-v-3dc8e15f] {
  color: #aaa;
  border: none;
}
.p-question-list .create-question[data-v-3dc8e15f] {
  position: fixed;
  bottom: 0.4rem;
  right: 0.4rem;
  height: 0.6rem;
  width: 0.6rem;
  padding: 0;
  border-radius: 100%;
  background-color: #59b6d7;
  border: 2px solid #fff;
  -webkit-box-shadow: 0 0 0.12rem 0 rgba(89, 182, 215, 0.43);
  box-shadow: 0 0 0.12rem 0 rgba(89, 182, 215, 0.43);
  z-index: 1;
}
.p-question-list .create-question > svg[data-v-3dc8e15f] {
  display: block;
  margin: auto;
  color: #fff;
}
.p-question-list .no-more[data-v-3dc8e15f] {
  height: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 0.28rem;
  color: #ccc;
}
.p-question-detail .tabbar[data-v-f834eb9a] {
  position: fixed;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 0.94rem;
  width: 100%;
  background-color: #fff;
  bottom: 0;
  border-top: solid 1px #d7d8d8;
}
@media screen and (min-width: 769px) {
.p-question-detail .tabbar[data-v-f834eb9a] {
    width: 7.68rem;
}
}
.p-question-detail .tabbar .tabbar-item[data-v-f834eb9a] {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  color: #999;
  font-size: 0.12rem;
}
.p-question-detail .tabbar .tabbar-icon[data-v-f834eb9a] {
  width: 0.32rem;
  height: 0.32rem;
  margin-bottom: 0.06rem;
}
.p-question-detail .main[data-v-f834eb9a] {
  background-color: #fff;
  padding: 0.3rem;
  width: 100%;
  max-width: 100%;
  height: auto;
}
.p-question-detail .main .main-topics[data-v-f834eb9a] {
  width: 100%;
  height: auto;
}
.p-question-detail .main .main-topics > .label[data-v-f834eb9a] {
  display: inline-block;
  background-color: #efefef;
  border-radius: 0.2rem;
  font-size: 0.24rem;
  color: #666;
  padding: 0.06rem 0.3rem;
  margin-right: 0.14rem;
  margin-bottom: 0.14rem;
}
.p-question-detail .main .main-title[data-v-f834eb9a] {
  font-size: 0.36rem;
  color: #333;
  font-weight: normal;
  line-height: 1.4;
  margin: 0.12rem 0;
}
.p-question-detail .main .main-author[data-v-f834eb9a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0.22rem;
  font-size: 80%;
  color: #ccc;
}
.p-question-detail .main .main-author > span[data-v-f834eb9a] {
  margin-left: 0.16rem;
  color: #666666;
}
.p-question-detail .main .main-watch[data-v-f834eb9a] {
  width: 100%;
  font-size: 0.26rem;
  color: #999;
  margin-top: 0.44rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.p-question-detail .main .main-watch .main-watch-follow[data-v-f834eb9a] {
  height: 0.5rem;
  width: 5.5em;
  padding: 0;
  border: solid 0.02rem #59b6d7;
  border-radius: 0.08rem;
  background-color: #fff;
  color: #59b6d7;
  font-size: 0.26rem;
  line-height: 0.05rem;
  outline: none;
}
.p-question-detail .main .main-watch .main-watch-follow > span[data-v-f834eb9a] {
  width: 0.2rem;
  height: 0.2rem;
  font-size: 0.36rem;
  margin-right: 0.02rem;
  vertical-align: middle;
}
.p-question-detail .main .main-watch .main-watch-follow .follow-btn[data-v-f834eb9a] {
  color: #59b6d7;
  width: 0.2rem;
  height: 0.2rem;
  vertical-align: baseline;
}
.p-question-detail .main .main-watch .main-watch-follow.active[data-v-f834eb9a] {
  color: #ccc;
  border: solid 0.02rem #ccc;
}
.p-question-detail .main .main-watch .main-watch-follow.active .follow-btn[data-v-f834eb9a] {
  color: #ccc;
}
.p-question-detail .main .main-watch .main-watch-count .split[data-v-f834eb9a]::before {
  content: '·';
  margin: 0 0.12rem;
  color: #999;
}
.p-question-detail .main .main-watch .main-watch-count .shang[data-v-f834eb9a] {
  color: #ff9400;
}
.p-question-detail .main .main-watch .main-watch-count .shang > span[data-v-f834eb9a] {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 0.34rem;
  width: 0.34rem;
  border: solid 1px currentColor;
  border-radius: 0.04rem;
  font-size: 0.22rem;
}
.p-question-detail .main-button[data-v-f834eb9a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  border-top: solid 1px #ededed;
  font-size: 0.28rem;
  background-color: #fff;
  padding: 0.3rem 0;
  position: relative;
}
.p-question-detail .main-button > .button[data-v-f834eb9a] {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  text-align: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #666;
  border-right: 1px solid #ededed;
}
.p-question-detail .main-button > .button[data-v-f834eb9a]:last-child {
  border-right: none;
}
.p-question-detail .main-button .main-button-icon[data-v-f834eb9a] {
  width: 0.3rem;
  height: 0.3rem;
  margin-top: -0.02rem;
  margin-right: 0.2rem;
}
.p-question-detail .main-button .show-invitations[data-v-f834eb9a] {
  position: absolute;
  top: 0.8rem;
  background: #fff;
  border-radius: 0.06rem;
  padding: 0.2rem 0.3rem;
  -webkit-box-shadow: 0 -0.01rem 0.03rem rgba(26, 26, 26, 0.1);
  box-shadow: 0 -0.01rem 0.03rem rgba(26, 26, 26, 0.1);
  font-size: 0.18rem;
}
.p-question-detail .main-button .show-invitations a.txt[data-v-f834eb9a] {
  margin-left: 0.1rem;
  color: #666;
}
.p-question-detail .answers-tool[data-v-f834eb9a] {
  background: #f4f5f6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.24rem;
  font-weight: normal;
  font-stretch: normal;
  color: #999;
  padding: 0.25rem 0.27rem;
}
.p-question-detail .answers-tool > button[data-v-f834eb9a] {
  background-color: transparent;
  outline: none;
  color: #999;
}
.p-question-detail .answers-tool > button > .icon[data-v-f834eb9a] {
  width: 0.3rem;
  height: 0.3rem;
  margin-bottom: -0.06rem;
  margin-left: 0.14rem;
}
.p-question-reward > main[data-v-8efd918c] {
  background-color: #fff;
}
.p-question-reward .set-reward[data-v-8efd918c] {
  padding: 0.2rem;
  font-size: 0.28rem;
  color: #999;
  margin-bottom: 0.2rem;
}
.p-question-reward .set-reward .reward-items[data-v-8efd918c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.p-question-reward .set-reward .reward-items > li[data-v-8efd918c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 0.2rem;
  margin-top: 0.2rem;
  width: 30%;
  height: 0.7rem;
  border: 0.01rem solid #ededed;
  border-radius: 0.08rem;
}
.p-question-reward .set-reward .reward-items > li.active[data-v-8efd918c] {
  color: #59b6d7;
  border-color: #59b6d7;
}
.p-question-reward .c-form-input-item[data-v-8efd918c] {
  border-top: 1px solid #ededed;
}
.p-question-reward .c-form-input-item[data-v-8efd918c] label {
  width: 6em;
  color: #333;
}
.p-question-reward .c-form-input-item[data-v-8efd918c] .input-wrap input {
  text-align: right;
}
.p-question-reward footer[data-v-8efd918c] {
  padding: 0.2rem;
}
.p-question-reward footer .rule[data-v-8efd918c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.28rem;
  margin: 0.08rem 0 0.16rem;
  color: #999;
}
.p-question-reward footer .rule .m-icon-svg[data-v-8efd918c] {
  margin-right: 0.5em;
  color: #999;
}
.p-question-reward footer .submit-button[data-v-8efd918c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  padding: 0.2rem;
  margin-top: 0.4rem;
  border-radius: 0.08rem;
  background-color: #59b6d7;
  color: #fff;
}
.module-questions-topics[data-v-51196be6] {
  padding-top: 1.82rem;
  padding-bottom: 0.01rem;
  min-height: 100vh;
}
.module-questions-topics .nav[data-v-51196be6] {
  position: fixed;
  top: 0.9rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 0.9rem;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  color: #999;
  font-size: 0.3rem;
  font-weight: normal;
  font-stretch: normal;
  line-height: 0;
  letter-spacing: 0;
  border-bottom: solid 1px #ededed;
  z-index: 10;
}
.module-questions-topics .nav > a[data-v-51196be6] {
  color: #d7d8d8;
}
.module-questions-topics .nav .active[data-v-51196be6] {
  color: #333;
}
.p-topic-detail main[data-v-42e775b4] {
  width: 100%;
  height: auto;
}
.p-topic-detail .share[data-v-42e775b4] {
  width: 0.4rem;
  height: 0.9rem;
  background-color: transparent;
  margin-top: 0.2rem;
}
.p-topic-detail .share > .icon[data-v-42e775b4] {
  width: 0.4rem;
  height: 0.4rem;
}
.p-topic-detail .topic[data-v-42e775b4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 1.75rem;
  padding: 0.28rem 0.43rem;
  background-color: #fff;
  border-bottom: solid 1px #ededed;
}
.p-topic-detail .topic > .avatar[data-v-42e775b4] {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.18rem;
  background-color: #ededed;
}
.p-topic-detail .topic .title[data-v-42e775b4] {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  min-width: 0;
}
.p-topic-detail .topic .title .topic-name[data-v-42e775b4] {
  display: block;
  font-size: 0.32rem;
  font-weight: normal;
  font-stretch: normal;
  color: #333;
}
.p-topic-detail .topic .title .label[data-v-42e775b4] {
  margin-top: 0.19rem;
  font-size: 0.28rem;
  font-weight: normal;
  font-stretch: normal;
  color: #666;
}
.p-topic-detail .topic .title .label > span[data-v-42e775b4] {
  color: #59b6d7;
}
.p-topic-detail .topic .follow[data-v-42e775b4] {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 0.5rem;
  min-width: 6em;
  border: solid 1px #59b6d7;
  border-radius: 0.08rem;
  background-color: #fff;
  color: #59b6d7;
  font-size: 0.24rem;
  outline: none;
}
.p-topic-detail .topic .follow .m-svg-mini[data-v-42e775b4] {
  width: 0.24rem;
  height: 0.24rem;
  margin-right: 0.1rem;
}
.p-topic-detail .topic .follow.active[data-v-42e775b4] {
  color: #ccc;
  border-color: #ccc;
}
.p-topic-detail .topic-desc[data-v-42e775b4] {
  width: 100%;
  padding: 0.29rem 0.3rem;
  background-color: #fff;
  color: #666;
  font-size: 0.28rem;
}
.p-topic-detail .experts[data-v-42e775b4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.3rem;
  border-top: solid 1px #ededed;
  background-color: #fff;
}
.p-topic-detail .experts > span[data-v-42e775b4] {
  font-size: 0.28rem;
  color: #999;
}
.p-topic-detail .experts .users[data-v-42e775b4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.p-topic-detail .experts .users .user[data-v-42e775b4] {
  position: relative;
  border: solid 1px #fff;
  margin-left: -0.2rem;
}
.p-topic-detail .experts .users .user[data-v-42e775b4]:first-child {
  margin-left: 0;
}
.p-topic-detail .types[data-v-42e775b4] {
  position: relative;
  top: 1.1rem;
  width: 100%;
  height: 0.9rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: -1rem;
  border-bottom: solid 0.01rem #ededed;
  background: #fff;
  color: #999;
  z-index: 1;
  font-size: 0.3rem;
}
.p-topic-detail .types > a[data-v-42e775b4] {
  color: #999;
}
.p-topic-detail .types .active[data-v-42e775b4] {
  color: #333;
}
.p-topic-detail .questions[data-v-42e775b4] {
  padding-top: 1.1rem;
}
.p-question-topic-apply .submit-btn[data-v-d0729fbe] {
  color: #59b6d7;
}
.p-question-topic-apply .submit-btn.disabled[data-v-d0729fbe] {
  color: #ccc;
}
.p-question-topic-apply .input-wrap[data-v-d0729fbe] {
  padding: 0.3rem 0.3rem 0;
  overflow: hidden;
  background-color: #fff;
  border-top: 0.01rem solid #ededed;
}
.p-question-topic-apply .input-wrap[data-v-d0729fbe]:first-child {
  border-top: none;
}
.c-question-comment-item[data-v-0b0de434] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.2rem;
  border-bottom: 0.01rem solid #ededed;
  background-color: #fff;
}
.c-question-comment-item .c-avatar[data-v-0b0de434] {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  -ms-flex-item-align: start;
  align-self: flex-start;
}
.c-question-comment-item .comment-info[data-v-0b0de434] {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  min-width: 0;
  margin-left: 0.2rem;
  font-size: 0.26rem;
}
.c-question-comment-item .comment-info .username[data-v-0b0de434] {
  color: #333;
}
.c-question-comment-item .comment-info p[data-v-0b0de434] {
  color: #999;
}
.c-question-comment-item .time[data-v-0b0de434] {
  -ms-flex-item-align: start;
  align-self: flex-start;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  font-size: 0.24rem;
  color: #bdbdbd;
}
.p-answer-detail .user-info-wrap[data-v-6616d34c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 0.2rem;
  height: 1.2rem;
  border-bottom: 1px solid #ededed;
}
.p-answer-detail .user-info-wrap .user-info[data-v-6616d34c] {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  margin-left: 0.2rem;
  font-size: 0.26rem;
  overflow: hidden;
}
.p-answer-detail .user-info-wrap .user-info .gray[data-v-6616d34c],
.p-answer-detail .user-info-wrap .user-info > p[data-v-6616d34c] {
  color: #999;
}
.p-answer-detail .user-info-wrap .user-info + span[data-v-6616d34c] {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  border: 1px solid #ccc;
  color: #ccc;
  border-radius: 0.1rem;
  font-size: 0.28rem;
  padding: 0.04rem 0.1rem;
  display: inline-block;
  width: 5em;
  text-align: center;
}
.p-answer-detail .user-info-wrap .user-info + span .follow-btn[data-v-6616d34c] {
  color: #ccc;
  width: 0.2rem;
  height: 0.2rem;
  vertical-align: baseline;
}
.p-answer-detail .user-info-wrap .user-info + span.actived[data-v-6616d34c] {
  color: #59b6d7;
  border-color: #59b6d7;
}
.p-answer-detail .user-info-wrap .user-info + span.actived .follow-btn[data-v-6616d34c] {
  color: #59b6d7;
}
.p-answer-detail .m-art-body[data-v-6616d34c] {
  margin-top: 0.1rem;
}
.p-answer-add[data-v-0e1c8020] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}
.p-answer-add .header .post-btn[data-v-0e1c8020] {
  color: #59b6d7;
}
.p-answer-add .header .post-btn.disabled[data-v-0e1c8020] {
  color: #ededed;
}
.p-answer-add > main[data-v-0e1c8020] {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  background-color: #fff;
}
.p-answer-add > main textarea[data-v-0e1c8020] {
  padding: 0.2rem;
  font-size: 0.3rem;
  width: 100%;
  height: 100%;
}
.p-question-search[data-v-3124cf18] {
  height: -webkit-calc(100% - 1.8rem);
  height: calc(100% - 1.8rem);
}
.p-question-search .m-head-top-title[data-v-3124cf18] {
  padding: 0 0.2rem 0 0;
}
.p-question-search .m-head-top-title .m-search-box[data-v-3124cf18] {
  width: 100%;
}
.p-question-search .questions-nav[data-v-3124cf18] {
  position: fixed;
  top: 0.9rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 0.9rem;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  color: #999;
  font-size: 0.3rem;
  font-weight: normal;
  font-stretch: normal;
  line-height: 0;
  letter-spacing: 0;
  border-bottom: solid 0.01rem #d7d8d8;
  z-index: 1;
}
@media screen and (min-width: 769px) {
.p-question-search .questions-nav[data-v-3124cf18] {
    width: 7.68rem;
}
}
.p-question-search .questions-nav > a[data-v-3124cf18] {
  color: #d7d8d8;
}
.p-question-search .questions-nav .active[data-v-3124cf18] {
  color: #333;
}
.p-question-search .m-no-find[data-v-3124cf18] {
  height: 100%;
}
.p-question-search .no-find-topics[data-v-3124cf18] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  color: #999;
  font-size: 0.28rem;
}
.p-question-search .no-find-topics > button[data-v-3124cf18] {
  margin-top: 0.3rem;
  padding: 0.2rem 0.4rem;
  background-color: #59b6d7;
  color: #fff;
  border-radius: 0.06rem;
}
.p-question-search .jo-loadmore-head {
  top: 1.82rem;
}

/*# sourceMappingURL=question.b9fcb4f2.css.map*/
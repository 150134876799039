.p-question-list {
  padding-top: 1.8rem;
  padding-bottom: 0.01rem;
  min-height: 100vh;
}

.p-question-list .nav {
  position: fixed;
  top: 0.9rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  max-width: 7.68rem;
  height: 0.9rem;
  background: #fff;
  color: #999;
  font-size: 0.3rem;
  border-bottom: solid 1px #dedede;
  z-index: 10;
}

.p-question-list .nav > a {
  color: #999;
}

.p-question-list .nav .active {
  color: #333;
}

.p-question-list .main .main-loading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.24rem;
}

.p-question-list .main .main-loading_icon {
  width: 1.2rem;
  height: 0.3rem;
  fill: #58b6d7;
}

.p-question-list .main .main-loadmore {
  margin: 0.24rem auto;
  text-align: center;
}

.p-question-list .main .main-loadmore_button {
  padding: 0.12rem 0.24rem;
  color: #58b6d7;
  background-color: transparent;
  border: solid 1px #58b6d7;
  border-radius: 0.06rem;
  outline: none;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.p-question-list .main .main-loadmore_button.active {
  color: #aaa;
  border: none;
}

.p-question-list .create-question {
  position: fixed;
  bottom: 0.4rem;
  right: 0.4rem;
  height: 0.6rem;
  width: 0.6rem;
  padding: 0;
  border-radius: 100%;
  background-color: #59b6d7;
  border: 2px solid #fff;
  -webkit-box-shadow: 0 0 0.12rem 0 rgba(89, 182, 215, 0.43);
  box-shadow: 0 0 0.12rem 0 rgba(89, 182, 215, 0.43);
  z-index: 1;
}

.p-question-list .create-question > svg {
  display: block;
  margin: auto;
  color: #fff;
}

.p-question-list .no-more {
  height: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 0.28rem;
  color: #ccc;
}
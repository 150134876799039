.p-answer-add {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}

.p-answer-add .header .post-btn {
  color: #59b6d7;
}

.p-answer-add .header .post-btn.disabled {
  color: #ededed;
}

.p-answer-add > main {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  background-color: #fff;
}

.p-answer-add > main textarea {
  padding: 0.2rem;
  font-size: 0.3rem;
  width: 100%;
  height: 100%;
}
.p-question-detail .tabbar {
  position: fixed;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 0.94rem;
  width: 100%;
  background-color: #fff;
  bottom: 0;
  border-top: solid 1px #d7d8d8;
}

@media screen and (min-width: 769px) {
  .p-question-detail .tabbar {
    width: 7.68rem;
  }
}

.p-question-detail .tabbar .tabbar-item {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  color: #999;
  font-size: 0.12rem;
}

.p-question-detail .tabbar .tabbar-icon {
  width: 0.32rem;
  height: 0.32rem;
  margin-bottom: 0.06rem;
}

.p-question-detail .main {
  background-color: #fff;
  padding: 0.3rem;
  width: 100%;
  max-width: 100%;
  height: auto;
}

.p-question-detail .main .main-topics {
  width: 100%;
  height: auto;
}

.p-question-detail .main .main-topics > .label {
  display: inline-block;
  background-color: #efefef;
  border-radius: 0.2rem;
  font-size: 0.24rem;
  color: #666;
  padding: 0.06rem 0.3rem;
  margin-right: 0.14rem;
  margin-bottom: 0.14rem;
}

.p-question-detail .main .main-title {
  font-size: 0.36rem;
  color: #333;
  font-weight: normal;
  line-height: 1.4;
  margin: 0.12rem 0;
}

.p-question-detail .main .main-author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0.22rem;
  font-size: 80%;
  color: #ccc;
}

.p-question-detail .main .main-author > span {
  margin-left: 0.16rem;
  color: #666666;
}

.p-question-detail .main .main-watch {
  width: 100%;
  font-size: 0.26rem;
  color: #999;
  margin-top: 0.44rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.p-question-detail .main .main-watch .main-watch-follow {
  height: 0.5rem;
  width: 5.5em;
  padding: 0;
  border: solid 0.02rem #59b6d7;
  border-radius: 0.08rem;
  background-color: #fff;
  color: #59b6d7;
  font-size: 0.26rem;
  line-height: 0.05rem;
  outline: none;
}

.p-question-detail .main .main-watch .main-watch-follow > span {
  width: 0.2rem;
  height: 0.2rem;
  font-size: 0.36rem;
  margin-right: 0.02rem;
  vertical-align: middle;
}

.p-question-detail .main .main-watch .main-watch-follow .follow-btn {
  color: #59b6d7;
  width: 0.2rem;
  height: 0.2rem;
  vertical-align: baseline;
}

.p-question-detail .main .main-watch .main-watch-follow.active {
  color: #ccc;
  border: solid 0.02rem #ccc;
}

.p-question-detail .main .main-watch .main-watch-follow.active .follow-btn {
  color: #ccc;
}

.p-question-detail .main .main-watch .main-watch-count .split::before {
  content: '·';
  margin: 0 0.12rem;
  color: #999;
}

.p-question-detail .main .main-watch .main-watch-count .shang {
  color: #ff9400;
}

.p-question-detail .main .main-watch .main-watch-count .shang > span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 0.34rem;
  width: 0.34rem;
  border: solid 1px currentColor;
  border-radius: 0.04rem;
  font-size: 0.22rem;
}

.p-question-detail .main-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  border-top: solid 1px #ededed;
  font-size: 0.28rem;
  background-color: #fff;
  padding: 0.3rem 0;
  position: relative;
}

.p-question-detail .main-button > .button {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  text-align: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #666;
  border-right: 1px solid #ededed;
}

.p-question-detail .main-button > .button:last-child {
  border-right: none;
}

.p-question-detail .main-button .main-button-icon {
  width: 0.3rem;
  height: 0.3rem;
  margin-top: -0.02rem;
  margin-right: 0.2rem;
}

.p-question-detail .main-button .show-invitations {
  position: absolute;
  top: 0.8rem;
  background: #fff;
  border-radius: 0.06rem;
  padding: 0.2rem 0.3rem;
  -webkit-box-shadow: 0 -0.01rem 0.03rem rgba(26, 26, 26, 0.1);
  box-shadow: 0 -0.01rem 0.03rem rgba(26, 26, 26, 0.1);
  font-size: 0.18rem;
}

.p-question-detail .main-button .show-invitations a.txt {
  margin-left: 0.1rem;
  color: #666;
}

.p-question-detail .answers-tool {
  background: #f4f5f6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.24rem;
  font-weight: normal;
  font-stretch: normal;
  color: #999;
  padding: 0.25rem 0.27rem;
}

.p-question-detail .answers-tool > button {
  background-color: transparent;
  outline: none;
  color: #999;
}

.p-question-detail .answers-tool > button > .icon {
  width: 0.3rem;
  height: 0.3rem;
  margin-bottom: -0.06rem;
  margin-left: 0.14rem;
}
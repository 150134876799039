.module-questions-topics {
  padding-top: 1.82rem;
  padding-bottom: 0.01rem;
  min-height: 100vh;
}

.module-questions-topics .nav {
  position: fixed;
  top: 0.9rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 0.9rem;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  color: #999;
  font-size: 0.3rem;
  font-weight: normal;
  font-stretch: normal;
  line-height: 0;
  letter-spacing: 0;
  border-bottom: solid 1px #ededed;
  z-index: 10;
}

.module-questions-topics .nav > a {
  color: #d7d8d8;
}

.module-questions-topics .nav .active {
  color: #333;
}
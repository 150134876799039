.p-answer-detail .user-info-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 0.2rem;
  height: 1.2rem;
  border-bottom: 1px solid #ededed;
}

.p-answer-detail .user-info-wrap .user-info {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  margin-left: 0.2rem;
  font-size: 0.26rem;
  overflow: hidden;
}

.p-answer-detail .user-info-wrap .user-info .gray,
.p-answer-detail .user-info-wrap .user-info > p {
  color: #999;
}

.p-answer-detail .user-info-wrap .user-info + span {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  border: 1px solid #ccc;
  color: #ccc;
  border-radius: 0.1rem;
  font-size: 0.28rem;
  padding: 0.04rem 0.1rem;
  display: inline-block;
  width: 5em;
  text-align: center;
}

.p-answer-detail .user-info-wrap .user-info + span .follow-btn {
  color: #ccc;
  width: 0.2rem;
  height: 0.2rem;
  vertical-align: baseline;
}

.p-answer-detail .user-info-wrap .user-info + span.actived {
  color: #59b6d7;
  border-color: #59b6d7;
}

.p-answer-detail .user-info-wrap .user-info + span.actived .follow-btn {
  color: #59b6d7;
}

.p-answer-detail .m-art-body {
  margin-top: 0.1rem;
}
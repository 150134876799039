.c-question-comment-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.2rem;
  border-bottom: 0.01rem solid #ededed;
  background-color: #fff;
}

.c-question-comment-item .c-avatar {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.c-question-comment-item .comment-info {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  min-width: 0;
  margin-left: 0.2rem;
  font-size: 0.26rem;
}

.c-question-comment-item .comment-info .username {
  color: #333;
}

.c-question-comment-item .comment-info p {
  color: #999;
}

.c-question-comment-item .time {
  -ms-flex-item-align: start;
  align-self: flex-start;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  font-size: 0.24rem;
  color: #bdbdbd;
}
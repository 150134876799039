.c-switch-bar {
  position: fixed;
  border-color: #dedede;
  z-index: 10;
}

.c-switch-bar .search-btn {
  color: #333;
}

.c-switch-bar .tab {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.c-switch-bar .tab .item {
  width: 1.25rem;
  height: 0.6rem;
  line-height: 0.6rem;
  text-align: center;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  border: solid 1px #59b6d7;
  color: #59b6d7;
  font-size: 0.32rem;
}

.c-switch-bar .tab .item.active {
  background: #59b6d7;
  color: #fff;
}

.c-switch-bar .tab .item:last-child {
  border-top-right-radius: 0.06rem;
  border-bottom-right-radius: 0.06rem;
}

.c-switch-bar .tab .item:first-child {
  border-top-left-radius: 0.06rem;
  border-bottom-left-radius: 0.06rem;
}
.p-question-search {
  height: -webkit-calc(100% - 1.8rem);
  height: calc(100% - 1.8rem);
}

.p-question-search .m-head-top-title {
  padding: 0 0.2rem 0 0;
}

.p-question-search .m-head-top-title .m-search-box {
  width: 100%;
}

.p-question-search .questions-nav {
  position: fixed;
  top: 0.9rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 0.9rem;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  color: #999;
  font-size: 0.3rem;
  font-weight: normal;
  font-stretch: normal;
  line-height: 0;
  letter-spacing: 0;
  border-bottom: solid 0.01rem #d7d8d8;
  z-index: 1;
}

@media screen and (min-width: 769px) {
  .p-question-search .questions-nav {
    width: 7.68rem;
  }
}

.p-question-search .questions-nav > a {
  color: #d7d8d8;
}

.p-question-search .questions-nav .active {
  color: #333;
}

.p-question-search .m-no-find {
  height: 100%;
}

.p-question-search .no-find-topics {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  color: #999;
  font-size: 0.28rem;
}

.p-question-search .no-find-topics > button {
  margin-top: 0.3rem;
  padding: 0.2rem 0.4rem;
  background-color: #59b6d7;
  color: #fff;
  border-radius: 0.06rem;
}
.p-question-reward > main {
  background-color: #fff;
}

.p-question-reward .set-reward {
  padding: 0.2rem;
  font-size: 0.28rem;
  color: #999;
  margin-bottom: 0.2rem;
}

.p-question-reward .set-reward .reward-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.p-question-reward .set-reward .reward-items > li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 0.2rem;
  margin-top: 0.2rem;
  width: 30%;
  height: 0.7rem;
  border: 0.01rem solid #ededed;
  border-radius: 0.08rem;
}

.p-question-reward .set-reward .reward-items > li.active {
  color: #59b6d7;
  border-color: #59b6d7;
}

.p-question-reward .c-form-input-item {
  border-top: 1px solid #ededed;
}

.p-question-reward .c-form-input-item /deep/ label {
  width: 6em;
  color: #333;
}

.p-question-reward .c-form-input-item /deep/ .input-wrap input {
  text-align: right;
}

.p-question-reward footer {
  padding: 0.2rem;
}

.p-question-reward footer .rule {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.28rem;
  margin: 0.08rem 0 0.16rem;
  color: #999;
}

.p-question-reward footer .rule .m-icon-svg {
  margin-right: 0.5em;
  color: #999;
}

.p-question-reward footer .submit-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  padding: 0.2rem;
  margin-top: 0.4rem;
  border-radius: 0.08rem;
  background-color: #59b6d7;
  color: #fff;
}
.p-question-topic-apply .submit-btn {
  color: #59b6d7;
}

.p-question-topic-apply .submit-btn.disabled {
  color: #ccc;
}

.p-question-topic-apply .input-wrap {
  padding: 0.3rem 0.3rem 0;
  overflow: hidden;
  background-color: #fff;
  border-top: 0.01rem solid #ededed;
}

.p-question-topic-apply .input-wrap:first-child {
  border-top: none;
}
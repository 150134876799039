.p-topic-detail main {
  width: 100%;
  height: auto;
}

.p-topic-detail .share {
  width: 0.4rem;
  height: 0.9rem;
  background-color: transparent;
  margin-top: 0.2rem;
}

.p-topic-detail .share > .icon {
  width: 0.4rem;
  height: 0.4rem;
}

.p-topic-detail .topic {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 1.75rem;
  padding: 0.28rem 0.43rem;
  background-color: #fff;
  border-bottom: solid 1px #ededed;
}

.p-topic-detail .topic > .avatar {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.18rem;
  background-color: #ededed;
}

.p-topic-detail .topic .title {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  min-width: 0;
}

.p-topic-detail .topic .title .topic-name {
  display: block;
  font-size: 0.32rem;
  font-weight: normal;
  font-stretch: normal;
  color: #333;
}

.p-topic-detail .topic .title .label {
  margin-top: 0.19rem;
  font-size: 0.28rem;
  font-weight: normal;
  font-stretch: normal;
  color: #666;
}

.p-topic-detail .topic .title .label > span {
  color: #59b6d7;
}

.p-topic-detail .topic .follow {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 0.5rem;
  min-width: 6em;
  border: solid 1px #59b6d7;
  border-radius: 0.08rem;
  background-color: #fff;
  color: #59b6d7;
  font-size: 0.24rem;
  outline: none;
}

.p-topic-detail .topic .follow .m-svg-mini {
  width: 0.24rem;
  height: 0.24rem;
  margin-right: 0.1rem;
}

.p-topic-detail .topic .follow.active {
  color: #ccc;
  border-color: #ccc;
}

.p-topic-detail .topic-desc {
  width: 100%;
  padding: 0.29rem 0.3rem;
  background-color: #fff;
  color: #666;
  font-size: 0.28rem;
}

.p-topic-detail .experts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.3rem;
  border-top: solid 1px #ededed;
  background-color: #fff;
}

.p-topic-detail .experts > span {
  font-size: 0.28rem;
  color: #999;
}

.p-topic-detail .experts .users {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.p-topic-detail .experts .users .user {
  position: relative;
  border: solid 1px #fff;
  margin-left: -0.2rem;
}

.p-topic-detail .experts .users .user:first-child {
  margin-left: 0;
}

.p-topic-detail .types {
  position: relative;
  top: 1.1rem;
  width: 100%;
  height: 0.9rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: -1rem;
  border-bottom: solid 0.01rem #ededed;
  background: #fff;
  color: #999;
  z-index: 1;
  font-size: 0.3rem;
}

.p-topic-detail .types > a {
  color: #999;
}

.p-topic-detail .types .active {
  color: #333;
}

.p-topic-detail .questions {
  padding-top: 1.1rem;
}